import { useEffect, useState } from "react";
import styles from "./ApplicationVersion.module.css";

const CLIENT_GIT_BRANCH = "stable/4.13.0";
const CLIENT_GIT_COMMIT = "10356fb6f3cda431da2f329da84597f8ec5ebcaf";
const CLIENT_BUILD_TIME = "2024-10-09T08:06:44UTC";

const URL: string = import.meta.env.VITE_API_URL;

interface ServerInfo {
    git_branch: string;
    git_commit: string;
    build_time: string;
    timestamp: number;
}

export const ApplicationVersion = () => {
    const [serverInfo, setServerInfo] = useState<ServerInfo | null>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.altKey && event.key === "i") {
                setIsVisible(!isVisible);
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isVisible]);

    useEffect(() => {
        const fetchServerInfoCall = async () => {
            try {
                const serverInfoResponse = await fetch(`${URL}/version`);
                if (serverInfoResponse.ok) {
                    const serverInfoJson = await serverInfoResponse.json();
                    setServerInfo({ ...serverInfoJson, timestamp: Date.now() });
                } else {
                    console.error("[ApplicationVersion]: Server Version Fetch responded with status", serverInfoResponse.status);
                }
            } catch (error) {
                console.error("[ApplicationVersion]: Server Version Fetch failed:", error);
            }
        };

        const fetchServerInfo = async () => {
            const cachedServerInfo = localStorage.getItem("server-info");
            const cachedServerInfoJson = cachedServerInfo ? JSON.parse(cachedServerInfo) as ServerInfo : null;
            if (cachedServerInfoJson && Date.now() - cachedServerInfoJson.timestamp <= 120000) {
                setServerInfo(cachedServerInfoJson as ServerInfo);
            } else {
                await fetchServerInfoCall();
            }
        };

        fetchServerInfo();
    }, []);

    useEffect(() => {
        localStorage.setItem("server-info", JSON.stringify(serverInfo));
    }, [serverInfo]);

    return (
        <span className={styles.versionInfo} style={{ display: isVisible ? "block" : "none" }}>
            {serverInfo ? (
                <>Server: {serverInfo.git_branch} ({serverInfo.git_commit}) - {serverInfo.build_time}<br /></>
            ) : (
                <>Loading server info...<br /></>
            )}
            Client: {CLIENT_GIT_BRANCH} ({CLIENT_GIT_COMMIT}) - {CLIENT_BUILD_TIME}<br/>
        </span>
    );
};
import {NewChatButton} from '../../components/NewChatButton';
import {Delete20Regular, PanelLeftContractRegular} from "@fluentui/react-icons";
import styles from './Chat.module.css'
import {DeleteAllButton} from '../../components/DeleteAllButton';
import * as React from "react";

interface Props {
    chatHistory: any;
    selectedChatHistory: string;
    lastQuestionRef: string;
    isLoading: boolean;
    onNewChatClicked: () => void;
    onHistoryClicked: (chatHistory: any) => void;
    onDeleteChatHistory: (chat_id: any) => void;
    onDeleteAllClicked: () => void;
    onToggleClicked: () => void;
}


export const ChatHistoryList = ({
                                    chatHistory, selectedChatHistory, lastQuestionRef, isLoading,
                                    onNewChatClicked, onHistoryClicked, onDeleteChatHistory, onDeleteAllClicked,
                                    onToggleClicked
                                }: Props) => {

    return (
        <>
            <div className={`${styles.containerHeader} ${styles.historyContainerHeader}`}>
                <PanelLeftContractRegular className={styles.historyContainerHeaderHide} onClick={onToggleClicked}/>
                {chatHistory.length > 0 && <NewChatButton onNewChatCliked={onNewChatClicked}
                                                          disabled={!lastQuestionRef || isLoading || chatHistory.length > 100}/>}
            </div>
            <div className={styles.historyContainerContentListWrapper}>
                <ul className={styles.historyContainerContentList}>
                    {Object.keys(chatHistory).map((key: any, index) => (
                        <li key={index}
                            className={`${styles.historyContainerContentHistory} 
                            ${selectedChatHistory === chatHistory[key].chat_id ? styles.historyContainerContentHistoryHighlight : ''}
                            `}>
                            <p onClick={() => onHistoryClicked(chatHistory[key])}>{chatHistory[key].user}</p>
                            <div className={styles.pointer}>
                                <Delete20Regular onClick={() => onDeleteChatHistory(chatHistory[key].chat_id)}/>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {chatHistory.length > 0 &&
                <div className={styles.historyContainerContentDeleteAll}>
                    <DeleteAllButton onDeleteAllClicked={onDeleteAllClicked}/>
                </div>
            }
        </>
    )
} 

import {Delete20Regular} from '@fluentui/react-icons';
import {Text} from "@fluentui/react"
import styles from './DeleteAllButton.module.css';
import {DefaultButton} from '@fluentui/react/lib/Button';
import {useState} from 'react';
import {ConfirmDialog} from '../ConfirmDialog';

interface Props {
    onDeleteAllClicked: () => void;
}

export const DeleteAllButton = ({ onDeleteAllClicked } : Props) => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const handleDeleteAll = () => {
        setShowConfirmationDialog(true);
    }

    const handleConfirmDeleteAll = () => {
        onDeleteAllClicked();
        setShowConfirmationDialog(false);
    }

    const handleCancelDeleteAll = () => {
        setShowConfirmationDialog(false);
    };

    return (
        <><
            DefaultButton className={`${styles.buttonContainer}`} onClick={handleDeleteAll}>
            <div className={styles.buttonText}>
                <Delete20Regular className={styles.text}/>
                <Text className={styles.text}>Delete all</Text>
            </div>
            </DefaultButton>
            {showConfirmationDialog &&
                <ConfirmDialog message="Are you sure you want to delete all conversations?"
                               onConfirm={handleConfirmDeleteAll}
                               onCancel={handleCancelDeleteAll}/>}
        </>
    )
}
import {Example} from "./Example";

import styles from "./Example.module.css";
import {IDocument} from "../../api";

export type ExampleModel = {
    source: string;
    data: {
        text: string;
        value: string;
        selectedDocuments: IDocument[]
    }[];
};

const EXAMPLES: ExampleModel[] = [
    {
        source: "chat",
        data: [
            {
                text: "Write a summary of existing IDC Research for Cybersecurity",
                value: "Write a summary of existing IDC Research for Cybersecurity",
                selectedDocuments: []
            },
            {
                text: "Give the summary of 'Showcasing Four Innovative Applications of Artificial Intelligence in Asia/Pacific Financial Services in 2022' and give 3 key take always",
                value: "Give the summary of 'Showcasing Four Innovative Applications of Artificial Intelligence in Asia/Pacific Financial Services in 2022' and give 3 key take always",
                selectedDocuments: [{
                    documentNumber: "AP46521820",
                    documentTitle: "Showcasing Four Innovative Applications of Artificial Intelligence in Asia/Pacific Financial Services in 2022"
                }]
            },
            {
                text: "List all research documents related to 5G",
                value: "List all research documents related to 5G",
                selectedDocuments: []
            },
            {
                text: "Write a summary of Asia/Pacific Greater China 2020–2021 Carrier Capex Spending Analysis and 2022–2026 Forecast",
                value: "Write a summary of Asia/Pacific Greater China 2020–2021 Carrier Capex Spending Analysis and 2022–2026 Forecast",
                selectedDocuments: []
            },
            {
                text: "Write a 150 words summary for The Impacts of the Omicron Wave, U.S. Restrictions on China Companies, and Russia–Ukraine War on the China ICT Market and Global Supply Chains",
                value: "Write a 150 words summary for The Impacts of the Omicron Wave, U.S. Restrictions on China Companies, and Russia–Ukraine War on the China ICT Market and Global Supply Chains",
                selectedDocuments: [{
                    documentNumber: "AP49056822",
                    documentTitle: "The Impacts of the Omicron Wave, U.S. Restrictions on China Companies, and Russia–Ukraine War on the China ICT Market and Global Supply Chains"}]
            },
            {
                text: "which analysts write about AIOps",
                value: "which analysts write about AIOps",
                selectedDocuments: []
            }
        ]
    },
    {
        source: "article",
        data: [
            {
                text: "Create a 800 word blog post from the report 'Key Highlights from the 2023 CIO Sentiment Survey.' Focus on up to 6 key observations. Provide actionable recommendations and make it conversation and casual",
                value: "Create a 800 word blog post from the report 'Key Highlights from the 2023 CIO Sentiment Survey.' Focus on up to 6 key observations. Provide actionable recommendations and make it conversation and casual",
                selectedDocuments: [{
                    documentNumber: "US51821924",
                    documentTitle: "Key Highlights from the 2023 CIO Sentiment Survey"
                }]
            }, {
                text: "Create an article with 600 words for the CIO magazine using the given report in a formal tone. Focus on up to 6 key take aways for an enterprise CIO. Provide 3 top actionable recommendations for the CIO and his organization. give it a professional title.",
                value: "Create an article with 600 words for the CIO magazine using the given report in a formal tone. Focus on up to 6 key take aways for an enterprise CIO. Provide 3 top actionable recommendations for the CIO and his organization. give it a professional title.",
                selectedDocuments: []
            }, {
                text: "Create a 1000 word article on how AI is shaping the enterprise security and privacy. specifically include pointers on Responsible AI and its importance. this article is to be published on CSO Online. Focus on up to 6 key take aways for an enterprise CSO. Provide 3 top actionable recommendations for the CSO and his organization. give it a professional title.",
                value: "Create a 1000 word article on how AI is shaping the enterprise security and privacy. specifically include pointers on Responsible AI and its importance. this article is to be published on CSO Online. Focus on up to 6 key take aways for an enterprise CSO. Provide 3 top actionable recommendations for the CSO and his organization. give it a professional title.",
                selectedDocuments: []
            }
        ]
    }
];

interface Props {
    dataSource: any;
    onExampleClicked: (value: string, selectedDocuments: IDocument[]) => void;
}

export const ExampleList = ({dataSource, onExampleClicked}: Props) => {
    const finalPrompt: any = [];

    dataSource.map((ds: any) => {
        const filteredPrompt = EXAMPLES.filter(example => example.source === ds);
        filteredPrompt.map(prompt => {
            prompt.data.map(p => {
                finalPrompt.push(p);
            });
        });
    });

    return (
        <ul className={styles.examples}>
            {finalPrompt.map((x: any, i: any) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} selectedDocuments={x.selectedDocuments} onClick={onExampleClicked}/>
                </li>
            ))}
        </ul>
    );
};

import React from "react";
import { useMsal } from "@azure/msal-react";
import { PrimaryButton } from "@fluentui/react";

import idcLogo from "./../../assets/idc_logo.svg";
import analysis from "./../../assets/analysis.svg";
import styles from "./login.module.css";

const Login = () => {
    const { instance } = useMsal();
    return (
        <div className={styles.login}>
            <div className={styles.content}>
                <div>
                    <img src={analysis} width={"520px"} alt={"Decorative image"}/>
                </div>
                <div style={{ marginLeft: "30px" }}>
                    <img src={idcLogo} alt={"IDC logo"}/>
                    <h2>To ensure security, please complete the sign-in process before moving forward.</h2>
                    <PrimaryButton onClick={() => instance.loginRedirect()}>Sign In</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default Login;

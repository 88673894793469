import { useState, useEffect, useRef } from "react";
import styles from "./Answer.module.css";

interface Props {
    loading: boolean;
    getElapsedTime: (value: any) => void;
}

export const AnswerTimer = ({ loading, getElapsedTime }: Props) => {
    const [time, setTime] = useState(0);
    const timerRef = useRef(time);
    timerRef.current = time;

    useEffect(() => {
        if (loading) {
            const interval = setInterval(() => {
                setTime(prev => prev + 1);
            }, 1000);
            return () => {
                timerRef.current > 0 && getElapsedTime(timerRef.current);
                clearInterval(interval);
            };
        } else {
            setTime(0);
        }
    }, [loading]);

    const minutes = Math.floor((time % 3600) / 60);

    const seconds = Math.floor(time % 60);

    return (
        <>
            <span className={styles.timerDisplay}>
                Generation time: {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
            </span>
        </>
    );
};

type HtmlParsedAnswer = {
    answerHtml: string;
    citationsLink: {}[];
    profilesLinks: {}[];
};

export function parseAnswerToHtml(answer: string, references: any): HtmlParsedAnswer {
    if (answer === undefined) {
        return {
            answerHtml: "",
            citationsLink: [],
            profilesLinks: []
        };
    }
    const parts = answer.trim().split(/\[([^\]]+)\]/g);
    if (references?.[0]?.accessible_until === undefined) {
            return {
                answerHtml: adjustHeadings(parts.join("")),
                citationsLink: [],
                profilesLinks: references
            };
    }
    return {
        answerHtml: adjustHeadings(parts.join("")),
        citationsLink: references,
        profilesLinks: []
    };
}

export function adjustHeadings(markdownText: string): string {
    function increaseLevel(match: string): string {
        const level = match[0].length;
        return '#'.repeat(level + levelIncrease);
    }

    function convertHtmlHeadersToMarkdown(text: string): string {
        return text.replace(/<h([1-6])>(.*?)<\/h\1>/gi, (_, level, content) => {
            return `${'#'.repeat(parseInt(level))} ${content.trim()} \n`;
        });
    }

    function convertBrToNewline(text: string): string {
        return text.replace(/<br\s*\/?>/gi, "\n");
    }

    markdownText = convertHtmlHeadersToMarkdown(markdownText);
    markdownText = convertBrToNewline(markdownText);

    let markdownHeader = ""
    let markdownBody = markdownText;
    if (markdownText.startsWith("##") && markdownText.includes('\n')) {
        const index = markdownText.indexOf('\n');
        markdownHeader = markdownText.substring(0, index);
        markdownBody = markdownText.substring(index + 1);
    }

    let levelIncrease = 0;
    if (/^# [^#]/m.test(markdownBody)) {     // Check if there is any heading level 1
        levelIncrease = 2;
    } else if (/^## [^#]/m.test(markdownBody)) { // Check if there is any heading level 2
        levelIncrease = 1;
    }

    // Adjust heading levels if necessary
    if (levelIncrease > 0) {
        markdownBody = markdownBody.replace(/^(#+)/gm, increaseLevel);
    }

    return markdownHeader + (markdownHeader === '' ? '' : '\n') + markdownBody;
}
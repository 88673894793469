import { Configuration } from "@azure/msal-browser";

const clientId = import.meta.env.VITE_CLIENT_ID;
const tenant = import.meta.env.VITE_TENANT;
const redirectUri = import.meta.env.VITE_REDIRECT_URI;

export const msalConfig: Configuration = {
    auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenant}/`,
        redirectUri: redirectUri
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
    }
};

import {DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton} from '@fluentui/react';
import React from 'react';

interface ConfirmDialogProps {
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ message, onConfirm, onCancel }) => {
    return (
        <Dialog
            hidden={false}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Confirmation'
            }}
        >
            <div>{message}</div>
            <DialogFooter>
                <PrimaryButton onClick={onConfirm} text="Confirm" />
                <DefaultButton onClick={onCancel} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};
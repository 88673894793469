import { Add20Regular } from "@fluentui/react-icons"
import { Text } from "@fluentui/react"
import styles from './NewChatButton.module.css'
import { DefaultButton } from '@fluentui/react/lib/Button';

interface Props{
    onNewChatCliked: () => void;
    disabled?: boolean;
}

export const NewChatButton = ({ onNewChatCliked, disabled } : Props) => {
    return (
            <DefaultButton className={`${styles.buttonContainer} ${disabled && styles.disabled}`}
                           onClick={onNewChatCliked}
                           aria-disabled={disabled}>
                <div className={styles.buttonText}>
                    <Add20Regular className={styles.text}/>
                    <Text className={styles.text}>New Conversation</Text>
                </div>
            </DefaultButton>
    )
}
import {
    Dropdown,
    Toggle,
    Panel
} from "@fluentui/react";
import styles from "./Chat.module.css";
import {IDropdownOption} from "@fluentui/react/lib/Dropdown";
import * as React from "react";

interface Props {
    chatTypes: { [key: string]: { key: string, text: string, supportResponseLengthLimitation: boolean } };
    responseLimitationChecked: boolean;
    onResponseLimitationChange: (value: boolean) => void;
    chatType: string;
    onChatTypeChange: (value: string) => void;
    isSettingsPanelOpen: boolean;
    onSettingPanelClosed: () => void;
}


export const ChatSettings = ({
                                 chatTypes,
                                 responseLimitationChecked,
                                 onResponseLimitationChange,
                                 chatType,
                                 onChatTypeChange,
                                 isSettingsPanelOpen,
                                 onSettingPanelClosed
                             }: Props) => {

    const onChangeResponseLimitation = (_: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        onResponseLimitationChange(!!checked);
    };

    const onChangeChatType = (_: any, option?: IDropdownOption) => {
        onChatTypeChange(option?.key as string);
        if (!chatTypes[option?.key as string].supportResponseLengthLimitation)
            onResponseLimitationChange(false);
    };

    const responseLengthLimitationSupported = () => {
        return chatTypes[chatType].supportResponseLengthLimitation;
    }

    const options = Object.keys(chatTypes)
        .map(key => ({key: key, text: chatTypes[key].text}));

    return (
        <div>
            <div className={styles.chatContainerHeaderSettings}>
                {responseLengthLimitationSupported() && (
                    <Toggle
                        label="Response Length Limitation"
                        title={"Having this feature on provides quicker responses, but the word count limit of the output is set to approximately 100."}
                        inlineLabel
                        checked={responseLimitationChecked}
                        onText=" "
                        offText=" "
                        className={styles.chatContainerHeaderSettingsToggle}
                        onChange={onChangeResponseLimitation}/>
                )}
                <Dropdown
                    className={styles.settingsArticle}
                    style={{width: '130px'}}
                    label="Action selection"
                    title={"Action selection"}
                    selectedKey={chatType}
                    onChange={onChangeChatType}
                    options={options}
                />
            </div>
            <Panel
                isOpen={isSettingsPanelOpen}
                onDismiss={() => onSettingPanelClosed()}
                headerText="Settings"
                closeButtonAriaLabel="Close"
                isFooterAtBottom={true}>
                <div className={styles.chatContainerHeaderSettingsInPanel}>
                    {responseLengthLimitationSupported() && (
                        <Toggle
                            label="Response Length Limitation"
                            title={"Having this feature on provides quicker responses, but the word count limit of the output is set to approximately 100."}
                            inlineLabel
                            checked={responseLimitationChecked}
                            onText=" "
                            offText=" "
                            className={styles.chatContainerHeaderSettingsToggle}
                            onChange={onChangeResponseLimitation}/>
                    )}
                    <Dropdown
                        className={styles.settingsArticle}
                        label="Action selection"
                        title={"Action selection"}
                        selectedKey={chatType}
                        onChange={onChangeChatType}
                        options={options}
                    />
                </div>
            </Panel>

        </div>
    );
};

import {ChatRequest} from "./models";
import {IMsalContext} from "@azure/msal-react/dist/MsalContext";
import {AuthenticationResult} from "@azure/msal-common";

const url: string = import.meta.env.VITE_API_URL;
const SCOPE = import.meta.env.VITE_SCOPE_URL;



export async function askApi(msal: IMsalContext, options: ChatRequest, signal: any): Promise<Response> {
    const response = await fetch(`${url}/ask`, {
        method: "POST",
        signal,
        headers: await buildHeaders(msal),
        body: JSON.stringify({
            history: options.history,
            preference: options.preference
        })
    });
    if (response.status > 299 || !response.ok) {
        const parsedResponse = await response.json();
        throw Error(parsedResponse.error || "Unknown error");
    }

    return response;
}

export async function getConversations(msal: IMsalContext) {
    const response = await fetch(`${url}/conversations`, {
        method: "GET",
        headers: await buildHeaders(msal),
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;

}

export async function getConversation(msal: IMsalContext, conversationId: string) {
    const response = await fetch(`${url}/conversations/${conversationId}`, {
        method: "GET",
        headers: await buildHeaders(msal),
    });

    const parsedResponse: any = await response.json();
    if (response.status === 403 || response.status === 404) {
        location.href = window.location.origin;
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;

}

export async function deleteConversation(msal: IMsalContext, conversationId: string) {
    const response = await fetch(`${url}/conversations/${conversationId}`, {
        method: "DELETE",
        headers: await buildHeaders(msal),
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;

}

export async function deleteAllConversations(msal: IMsalContext) {
    const response = await fetch(`${url}/conversations/all`, {
        method: "DELETE",
        headers: await buildHeaders(msal),
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function feedbackApi(msal: IMsalContext, feedbackData: any): Promise<any> {
    const response = await fetch(`${url}/conversations/feedback`, {
        method: "POST",
        headers: await buildHeaders(msal),
        body: JSON.stringify(feedbackData)
    });
    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function lookupDocumentApi(msal: IMsalContext, term: string, alreadySelected: string[], signal: AbortSignal) {
    let queryParams = `term=${encodeURIComponent(term)}`;
    if (alreadySelected.length > 0) {
        const alreadySelectedParam = alreadySelected.map(id => `alreadySelected=${encodeURIComponent(id)}`).join('&');
        queryParams += `&${alreadySelectedParam}`;
    }
    const fullUrl = `${url}/get_documents_by_term?${queryParams}`;
    const response = await fetch(fullUrl, {
        method: "GET",
        signal: signal,
        headers: await buildHeaders(msal)
    });
    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;

}

async function buildHeaders(msal: IMsalContext) {
    const token = await getToken(msal);
    return {
        "Content-Type": "application/json",
        Authorization: token
    }
}

async function getToken(msal: IMsalContext): Promise<string> {
    const request = {
        account: msal.accounts[0],
        scopes: [SCOPE]
    };
    const authResult: AuthenticationResult = await msal.instance.acquireTokenSilent(request)
    return authResult.accessToken;
}



import {Stack} from "@fluentui/react";
import {animated, useSpring} from "@react-spring/web";

import styles from "./Answer.module.css";
import {AnswerIcon} from "./AnswerIcon";
import React, {useEffect, useRef, useState} from "react";
import {AnswerTimer} from "./AnswerTimer";
import {IConversationMessage} from "../../api";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

interface Props {
    loading: boolean;
    message: IConversationMessage,
    getElapsedTime: (value: any) => void;
    onScrollRequested: () => void;
}

export const AnswerLoading = ({
                                  loading,
                                  message,
                                  getElapsedTime,
                                  onScrollRequested
                              }: Props) => {

    const animatedStyles = useSpring({
        from: {opacity: 0},
        to: {opacity: 1}
    });

    const [displayedText, setDisplayedText] = useState('');
    const typingIntervalRef = useRef(0);
    const typedTextRef = useRef('');

    const markdownRef = useRef(null);
    const latestDeepestLastChild = useRef<any>(null);

    useEffect(() => {
        // Clear existing interval if any
        if (typingIntervalRef.current) {
            clearInterval(typingIntervalRef.current);
        }

        // Only start typing if there's a new message and typing isn't completed
        if (message.bot && message.bot.answer) {
            const initText = message.bot.answer;
            const nonDisplayText = initText.substring(typedTextRef.current.length);
            let interval = Math.ceil(2000 / nonDisplayText.length);
            let charWriteCount = 1;
            while (interval < 100) {
                charWriteCount++;
                interval = Math.ceil(2000 / (nonDisplayText.length / charWriteCount));
            }
            typingIntervalRef.current = setInterval(() => {
                setDisplayedText(_ => {
                    const newLength = Math.min(typedTextRef.current.length + charWriteCount, initText.length);
                    const addedString = initText.substring(typedTextRef.current.length, newLength);
                    typedTextRef.current = initText.substring(0, newLength);
                    if (addedString.includes('\n')) {
                        onScrollRequested();
                    }
                    return typedTextRef.current;
                });
                // Check if typing is completed
                if (typedTextRef.current.length >= initText.length) {
                    clearInterval(typingIntervalRef.current);
                }
            }, interval);
        }

        // Cleanup function to clear the interval when component unmounts or message changes
        return () => {
            clearInterval(typingIntervalRef.current);
        };
    }, [message]);

    useEffect(() => {
        // Function to find the deepest last child
        const findDeepestLastChild = (node: any) => {
            while (node && node.lastElementChild && node.lastElementChild.nodeName !== 'STRONG') {
                node = node.lastElementChild;
            }
            return node;
        };

        if (markdownRef.current) {
            const container = markdownRef.current;
            const deepestLastChild = findDeepestLastChild(container);
            if (!deepestLastChild) {
                return;
            }
            if (latestDeepestLastChild.current) {
                latestDeepestLastChild.current.classList.remove(styles.blinkCursor);
            }
            deepestLastChild.classList.add(styles.blinkCursor);
            latestDeepestLastChild.current = deepestLastChild;
        }
    }, [displayedText]);


    return (
        <animated.div style={{...animatedStyles}}>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <Stack.Item className={styles.stopIconBlock}>
                    <AnswerIcon/>
                </Stack.Item>
                <Stack.Item grow>
                    <p className={styles.answerText}>
                        {displayedText ? (
                            <div ref={markdownRef}>
                                <ReactMarkdown className={styles.loadingAnswerText}
                                               children={displayedText} remarkPlugins={[remarkGfm]}/>
                            </div>
                        ) : message?.bot?.progress ? (
                            <>
                                <span>{message.bot.progress}</span>
                                <span className={styles.loadingDots}></span>
                            </>
                        ) : (
                            <>
                                <span>Generating answer</span>
                                <span className={styles.loadingDots}></span>
                            </>
                        )}

                    </p>
                </Stack.Item>
            </Stack>
            <AnswerTimer loading={loading} getElapsedTime={getElapsedTime}/>
        </animated.div>
    );
};

import React from "react";
import styles from "./UserAvatar.module.css";

interface Props {
    name: string;
}

export const UserAvatar = ({ name }: Props) => {
    return (
        <div className={styles.nameLabel}>
            {name?.split(" ")[0][0]}
            {name?.split(" ")[1][0]}
        </div>
    );
};

import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Copy20Regular,
    ThumbDislike20Regular,
    ThumbDislike24Regular,
    ThumbLike20Regular,
    ThumbLike24Regular,
    Checkmark20Regular
} from "@fluentui/react-icons";
import {
    Callout,
    DefaultButton,
    DelayedRender,
    FontWeights,
    IButtonStyles,
    IIconProps,
    IconButton,
    Modal,
    Stack,
    TextField,
    getTheme,
    mergeStyleSets,
    Text,
    DirectionalHint,
    IStackProps,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import {parseAnswerToHtml} from "./AnswerParser";
import {AnswerIcon} from "./AnswerIcon";
import {useId, useBoolean} from "@fluentui/react-hooks";
import styles from "./Answer.module.css";
import {feedbackApi} from "../../api";
import ReactMarkdown from "react-markdown";
import {useMsal} from "@azure/msal-react";
import remarkGfm from "remark-gfm";
import {a} from "@react-spring/web";

interface Props {
    answer: any;
    references: any;
    isSelected?: boolean;
    chatId: any;
    user: any;
    gptperformancemetricsId: any;
    history: (value: any) => void;
}

const cancelIcon: IIconProps = {iconName: "Cancel"};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        height: "280px",
        width: "55%",
        borderRadius: "10px"
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px"
        }
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: "18px",
        margin: "0"
    },
    body: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        selectors: {
            p: {margin: "14px 0"},
            "p:first-child": {marginTop: 0},
            "p:last-child": {marginBottom: 0}
        }
    }
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px"
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};

const thumbsUpCustomStyles = mergeStyleSets({
    button: {
        width: 130
    },
    callout: {
        maxWidth: 800,
        maxHeigth: 800,
        padding: "10px"
    }
});

const roundedTextFieldStyles = {
    fieldGroup: {
        borderRadius: "5px"
    }
};

const rowProps: IStackProps = {horizontal: true, verticalAlign: "center"};

const tokens = {
    sectionStack: {
        childrenGap: 10
    },
    spinnerStack: {
        childrenGap: 20
    }
};

export const Answer = ({answer, references, isSelected, chatId, user, gptperformancemetricsId, history}: Props) => {
    const displayCountDefault: number = 5;
    const msal = useMsal();
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer, references), [answer]);
    const copyRef = useRef<HTMLDivElement>(null);
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
    const [thumbsUp, setThumbsUp] = useState(false);
    const feedbackRef = useRef<any>();
    const titleId = useId("title");
    const [thumbsUpCalloutVisible, {toggle: togglethumbsUpCalloutVisible}] = useBoolean(false);
    const [thumbsDownCalloutVisible, {toggle: togglethumbsDownCalloutVisible}] = useBoolean(false);
    const [performancemetricsId, setPerformancemetricsId] = useState<any>({});
    const [spinLoading, setSpinLoading] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [thumbsUpFeedback, setThumbsUpFeedback] = useState<string>("");
    const [thumbsDownFeedback, setThumbsDownFeedback] = useState<string>("");
    const buttonId = useId("callout-button");
    const thumbsupId = useId("thumbsup");
    const thumbsdownId = useId("thumbsdown");
    const [displayCount, setDisplayCount] = useState(displayCountDefault);
    const [copyButtonClicked, setCopyButtonClicked] = useState<boolean>(false);

    useEffect(() => {
        setDisplayCount(5);
    }, [parsedAnswer.citationsLink]);

    const generateReferenceElement = (reference: any, i: number) => {
        return generateDocumentReferenceElement(reference, i);
    }

    const generateDocumentReferenceElement = (reference: any, i: number) => {
        let publication_date, accessible_until;
        if (reference.publication_date !== undefined) {
            publication_date = new Date(reference.publication_date);
            accessible_until = new Date(reference.accessible_until)
        }
        // Backward support for old chat histories which use timestamp and updated_date instead of publication_date
        else {
            publication_date = new Date(reference.updated_date * 1000);
            accessible_until = new Date(reference.accessible_until * 1000);
        }
        const monthName = publication_date.toLocaleString('en-US', {month: 'long'});

        let authors = reference.authors;
        if (typeof(authors) === 'object') {
            authors = authors.join(' | ');
        }

        let doc_number = reference.doc_number;
        if (doc_number === undefined) {
            doc_number = reference.number;
        }

        const text = reference.title + ', ' + authors + ' (' + doc_number + ', ' + monthName + ', ' + publication_date.getFullYear() + ')';

        if (accessible_until.getTime() > new Date().getTime()) {
            return (
                <a key={i} href={reference.link} target="_blank" className={styles.citation} title={text}>
                    <div>{`${++i})\u00a0`}</div>
                    <div className={styles.citationLink}>{`${text}`}</div>
                </a>
            );
        }
        return (
            <a key={i} className={styles.citationUnavailable} title={text}>
                <div>{`${++i})\u00a0`}</div>
                <div className={styles.citationLink}>{`${text}`}</div>
            </a>
        );
    }

    const showMoreItems = () => {
        // Update the state to display all items
        setDisplayCount(parsedAnswer.citationsLink.length);
    };

    const handleThumbsUpClick = (performancemetrics: any) => {
        setPerformancemetricsId(performancemetrics);
        if (performancemetrics.rating == "0" || performancemetrics.rating === undefined) {
            showModal();
        }
        setThumbsUp(true);
    };

    const handleThumbsDownClick = (performancemetrics: any) => {
        setPerformancemetricsId(performancemetrics);
        if (performancemetrics.rating == "0" || performancemetrics.rating === undefined) {
            showModal();
        }
        setThumbsUp(false);
    };

    const copyTextWithAllReferences = () => {
        const textToCopy = copyRef.current?.innerText;
        if (textToCopy) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    console.log('Text copied to clipboard');
                })
                .catch((err) => console.error('Unable to copy text to clipboard', err));
        }
        setCopyButtonClicked(true);
        setTimeout(() => {
            setCopyButtonClicked(false);
        }, 2000);
    }

    const copyText = () => {
        if (displayCount === parsedAnswer.citationsLink.length) {
            copyTextWithAllReferences();
        } else {
            setDisplayCount(parsedAnswer.citationsLink.length);
            setTimeout(copyTextWithAllReferences, 0);
        }
    }

    const handleSubmit = () => {
        setButtonDisabled(true);
        setSpinLoading(true);
        const feedback = {
            gptperformancemetrics_id: {conversation_id: performancemetricsId.conversation_id, message_id: performancemetricsId.message_id},
            user_feedback: feedbackRef.current.value,
            rating: thumbsUp ? 1 : 2
        };
        feedbackApi(msal, feedback).then(_ => {
            hideModal();
            setSpinLoading(false);
            setButtonDisabled(false);
            history({chat_id: chatId, user: user});
        });
    };

    const showThumbsUpCallout = (performancemetrics: any) => {
        const feedback = performancemetrics.rating == "1" ? performancemetrics.feedback : "";
        setThumbsUpFeedback(feedback);
        feedback != "" && togglethumbsUpCalloutVisible();
    };

    const hideThumbsUpCallout = () => {
        thumbsUpFeedback != "" && togglethumbsUpCalloutVisible();
    };

    const showThumbsDownCallout = (performancemetrics: any) => {
        const feedback = performancemetrics.rating == "2" ? performancemetrics.feedback : "";
        setThumbsDownFeedback(feedback);
        feedback != "" && togglethumbsDownCalloutVisible();
    };

    const hideThumbsDownCallout = () => {
        thumbsDownFeedback != "" && togglethumbsDownCalloutVisible();
    };

    const calloutDirectionalHint: DirectionalHint = DirectionalHint.topCenter;

    return (
        <div ref={copyRef}>
            <Modal titleAriaId={titleId} isOpen={isModalOpen} onDismiss={hideModal} isBlocking={true}
                   containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <h2 className={contentStyles.heading} id={titleId}>
                        <div className={styles.feedbackHeading}>
                            <div className={thumbsUp ? styles.thumbsLikeContainer : styles.thumbsDislikeContainer}>
                                {thumbsUp ? <ThumbLike24Regular className={styles.thumbLike}/> :
                                    <ThumbDislike24Regular className={styles.thumbDislike}/>}
                            </div>
                            Provide additional feedback
                        </div>
                    </h2>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={hideModal}/>
                </div>
                <div className={styles.divider}></div>
                <div className={contentStyles.body}>
                    <TextField
                        multiline
                        rows={4}
                        resizable={false}
                        componentRef={feedbackRef}
                        placeholder={thumbsUp ? "What do you like about the Response?(Optional)" : "What was the issue with the response?(Optional)"}
                        styles={roundedTextFieldStyles}
                    />
                    <Stack {...rowProps} tokens={tokens.spinnerStack} horizontalAlign="end">
                        <DefaultButton className={styles.modelSubmitBtn} onClick={handleSubmit} disabled={buttonDisabled}>
                            <Stack horizontal verticalAlign="center" tokens={{childrenGap: 8}}>
                                {spinLoading && <Spinner size={SpinnerSize.small}/>}
                                <span>Submit feedback</span>
                            </Stack>
                        </DefaultButton>
                    </Stack>
                </div>
            </Modal>

            <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <AnswerIcon/>
                    </Stack>
                </Stack.Item>

                <Stack.Item grow>
                    <ReactMarkdown className={styles.answerText} children={parsedAnswer.answerHtml} remarkPlugins={[remarkGfm]}/>
                </Stack.Item>

                {parsedAnswer.citationsLink != undefined && parsedAnswer.citationsLink.length > 0 && (
                    <Stack.Item>
                        <Stack horizontal tokens={{childrenGap: 5}} className={styles.citationStack}>
                            <span className={styles.citationLearnMore}>References:</span>
                            <div className={styles.gridWrap}>
                                {parsedAnswer.citationsLink.slice(0, displayCount).map((reference: any, i: number) => {
                                    return generateReferenceElement(reference, i);
                                })}
                            </div>
                        </Stack>
                        {parsedAnswer.citationsLink.length > displayCount && (
                            <a onClick={showMoreItems} title="Show all references">
                                <div className={styles.showAllCitations}>Show all references</div>
                            </a>
                        )}
                    </Stack.Item>
                )}
                {parsedAnswer.profilesLinks != undefined && parsedAnswer.profilesLinks.length > 0 && (
                    <Stack.Item>
                        <Stack horizontal tokens={{childrenGap: 5}} className={styles.profileStack}>
                            <span className={styles.citationLearnMore}>References:</span>
                            <div className={styles.gridWrap}>
                                {parsedAnswer.profilesLinks.map((profile: any, i: number) => {
                                    return (
                                        <a key={i} href={profile.link} target="_blank" className={styles.citation} title={profile.text}>
                                            <div>{`${++i})\u00a0`}</div>
                                            <div className={styles.citationLink}>{`${profile.text}`}</div>
                                        </a>
                                    );
                                })}
                            </div>
                        </Stack>
                    </Stack.Item>
                )}

                <Stack.Item>
                    <div className={styles.review}>
                        {copyButtonClicked ? (
                            <Checkmark20Regular className={styles.copyClicked} id={buttonId}/>
                        ) : (
                            <Copy20Regular className={styles.copyClip} onClick={copyText} id={buttonId}/>
                        )}
                        {gptperformancemetricsId && <ThumbLike20Regular
                            id={thumbsupId}
                            className={
                                gptperformancemetricsId.rating == "1"
                                    ? styles.thumbsUpRes
                                    : gptperformancemetricsId.rating == "2"
                                        ? styles.thumbsUpDisable
                                        : styles.thumbsUp
                            }
                            onMouseEnter={() => showThumbsUpCallout(gptperformancemetricsId)}
                            onMouseLeave={hideThumbsUpCallout}
                            onClick={() => handleThumbsUpClick(gptperformancemetricsId)}
                        />}
                        {gptperformancemetricsId && <ThumbDislike20Regular
                            id={thumbsdownId}
                            className={
                                gptperformancemetricsId.rating == "2"
                                    ? styles.thumbsDownRes
                                    : gptperformancemetricsId.rating == "1"
                                        ? styles.thumbsDownDisable
                                        : styles.thumbsDown
                            }
                            onMouseEnter={() => showThumbsDownCallout(gptperformancemetricsId)}
                            onMouseLeave={hideThumbsDownCallout}
                            onClick={() => handleThumbsDownClick(gptperformancemetricsId)}
                        />}
                    </div>
                </Stack.Item>
            </Stack>

            {thumbsUpCalloutVisible && (
                <Callout
                    className={thumbsUpCustomStyles.callout}
                    target={`#${thumbsupId}`}
                    onDismiss={togglethumbsUpCalloutVisible}
                    role="alert"
                    directionalHint={calloutDirectionalHint}
                >
                    <DelayedRender>
                        <Text variant="small">{thumbsUpFeedback}</Text>
                    </DelayedRender>
                </Callout>
            )}

            {thumbsDownCalloutVisible && (
                <Callout
                    className={thumbsUpCustomStyles.callout}
                    target={`#${thumbsdownId}`}
                    onDismiss={togglethumbsDownCalloutVisible}
                    role="alert"
                    directionalHint={calloutDirectionalHint}
                >
                    <DelayedRender>
                        <Text variant="small">{thumbsDownFeedback}</Text>
                    </DelayedRender>
                </Callout>
            )}
        </div>
    );
};

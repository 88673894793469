import { Stack, PrimaryButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";

import styles from "./Answer.module.css";
import React from 'react';
import {useMsal} from "@azure/msal-react";

interface Props {
    error: string;
    onRetry: () => void;
}

export const AnswerError = ({ error, onRetry }: Props) => {
    const msal = useMsal();
    const redirectToLogin = () => {
        msal.instance.loginRedirect();
    };

    if (error === 'Unauthorized') {
        return (
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />

                <Stack.Item grow>
                    <p className={styles.answerText}>{"Please sign in to continue (expired or unauthorized)."}</p>
                </Stack.Item>

                <PrimaryButton className={styles.retryButton} onClick={redirectToLogin}>Sign In</PrimaryButton>
            </Stack>
        );
    }
    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />

            <Stack.Item grow>
                <p className={styles.answerText}>{error}</p>
            </Stack.Item>

            <PrimaryButton className={styles.retryButton} onClick={onRetry} text="Retry" />
        </Stack>
    );
};

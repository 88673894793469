import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import Login from "./pages/login/Login";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: any) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        msalInstance.setActiveAccount(event.payload.account);
    }
});

initializeIcons();

export default function App() {
    return (
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <AuthenticatedTemplate>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Chat />} />
                            <Route path=":conversationID" element={<Chat />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Routes>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Login />} />
                            <Route path=":conversationID" element={<Login />} />
                        </Route>
                    </Routes>
                </UnauthenticatedTemplate>
            </BrowserRouter>
        </MsalProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
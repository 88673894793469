import styles from "./UserChatMessage.module.css";
import {IDocument} from "../../api";

interface Props {
    message: string;
    selectedDocuments: IDocument[] | undefined
    userIcon: any;
}

export const UserChatMessage = ({message, userIcon, selectedDocuments}: Props) => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <div className={styles.message}>{message}</div>
                    {userIcon}
                </div>
                {selectedDocuments && selectedDocuments.length > 0 && (
                    <span className={styles.selectedDocuments}>
                    User select documents:
                        {selectedDocuments.map((doc, index) => (
                            <span key={'selectedDocument' + doc.documentNumber}>
                                &nbsp;
                                <a target="_blank"
                                   href={'https://www.idc.com/getdoc.jsp?containerId=' + doc.documentNumber}>{doc.documentNumber}</a>
                            </span>
                        ))}
                </span>
                )}
            </div>
        </>
    );
};

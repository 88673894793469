import * as React from "react";

import styles from "./Chat.module.css";
import idc_logo from "./../../assets/idc_logo.svg";

import {useRef, useState, useEffect} from "react";
import {useMsal} from "@azure/msal-react";
import {Spinner, Toggle} from "@fluentui/react";
import {
    ChatRequest,
    ChatTurn,
    deleteAllConversations,
    getConversation,
    getConversations,
    deleteConversation, IDocument, IConversationMessage, askApi
} from "../../api";
import {Answer, AnswerError, AnswerLoading} from "../../components/Answer";
import {QuestionInput} from "../../components/QuestionInput";
import {ExampleList} from "../../components/Example";
import {UserChatMessage} from "../../components/UserChatMessage";
import {UserAvatar} from "../../components/UserAvatar";
import {ChatSettings} from "./ChatSettings";
import {AnswerTimerElapsed} from "../../components/Answer/AnswerTimerElapsed";
import {ChatHistoryList} from "./ChatHistoryList";
import {ApplicationVersion} from "../../components/ApplicationVersion/ApplicationVersion";
import DocumentAutoComplete from "../../components/DocumentAutocomplete/DocumentAutocomplete";
import {
    ComposeRegular, LauncherSettingsRegular, PanelLeftExpandRegular,
    PanelRightContractRegular,
    PanelRightExpandRegular
} from "@fluentui/react-icons";
import {useBoolean} from "@fluentui/react-hooks";

const Chat = () => {
    const chatTypes: { [key: string]: any } = {
        chat: {
            key: 'chat',
            text: 'Chat',
            titlePrimary: 'Discover new insights',
            secondaryTitle: 'delivering brief explanations',
            supportResponseLengthLimitation: true,
            supportSelectDocuments: true,
            inputPlaceholders: {
                simple: "Type a new question",
                withDocuments: "Type a new question (response will be generated based on the selected documents)"
            }
        },
        article: {
            key: 'article',
            text: 'Article',
            titlePrimary: 'Construct new articles',
            secondaryTitle: 'generating articles',
            supportResponseLengthLimitation: false,
            supportSelectDocuments: true,
            inputPlaceholders: {
                simple: "Enter your article requirements here",
                withDocuments: "Enter your article requirements here (article will be generated based on the selected documents)"
            }
        }

    };

    const msal = useMsal();
    let accountInf: any;
    if (msal.instance) {
        accountInf = msal.instance.getActiveAccount();
    }

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingConversation, setIsLoadingConversation] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [answers, setAnswers] = useState<IConversationMessage[]>([]);
    const loadingMessageRef = useRef<IConversationMessage>({} as IConversationMessage);
    const [loadingMessage, setLoadingMessage] = useState<IConversationMessage>({} as IConversationMessage);

    const [elapsedTime, setElapsedTime] = useState(0);
    const [responseLimitationFrame, setResponseLimitationFrame] = useState(true);
    const [chatType, setChatType] = useState('chat');
    const [expectedWordCount, setExpectedWordCount] = useState(100);
    const [loadingText, setLoadingText] = useState("Generating answer");

    const parseChatIdFromUrl = () => {
        const pathName = new URL(window.location.href).pathname;
        const pathLocation = pathName.split('/').filter(segment => segment)[0];
        return pathLocation ? pathLocation : "";
    }
    const [chatId, setChatId] = useState(() => parseChatIdFromUrl());

    const [chatHistory, setChatHistory] = useState<any[]>([]);
    const [selectedChatHistory, setSelectedChatHistory] = useState("");
    const [scroll, setScroll] = useState(false);
    const examplePromptClicked = useRef<boolean>(false);
    const [wantSelectDocuments, setWantSelectDocuments] = useState<boolean>(false);
    const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>([]);

    // Settings
    const [isSettingsOpen, {setTrue: openSettingsPanel, setFalse: closeSettingsPanel}] = useBoolean(false);

    const makeApiRequest = async (question: string, selectedDocuments: IDocument[]) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setLoadingText("Generating answer");

        const history: ChatTurn[] = answers.map(a => ({user: a.user, bot: a.bot?.answer}));
        const request: ChatRequest = {
            history: examplePromptClicked.current ? [{user: question, bot: undefined}] : [...history, {
                user: question,
                bot: undefined
            }],
            preference: {
                selectedDocuments: selectedDocuments,
                expectedWordCount: expectedWordCount,
                chatId: examplePromptClicked.current ? "" : chatId,
                type: chatType,
                stream: true
            }
        };

        try {
            loadingMessageRef.current = {} as IConversationMessage;
            setLoadingMessage({...loadingMessageRef.current});
            const response = await askApi(msal, request, undefined);
            if (!response || !response.body) {
                setError("Something went wrong! Please contact the administrator.");
                return;
            }
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let incomplete_line: string | undefined = '';
            while (true) {
                const {done, value} = await reader.read();
                const text: string = (incomplete_line ? incomplete_line : '') + decoder.decode(value, {stream: true});
                const lines = text.split('\n');
                incomplete_line = lines.pop();
                for (const line of lines) {
                    processLine(line)
                }
                if (done) {
                    break;
                }
            }

            if (incomplete_line) {
                processLine(incomplete_line)
            }
            // Add final answer to answers and scroll
            setAnswers((prevAnswers) => [...prevAnswers, {...loadingMessageRef.current}]);
            scrollAfterRender();
            // Reload chat history
            setChatId(loadingMessageRef.current.chat_id);
            setSelectedChatHistory(loadingMessageRef.current.chat_id);
            fetchChatHistory(undefined);
        } catch (e: any) {
            console.error("Error:", e);
            if (e.error) {
                if (e.code === 'ConversationNotFound') {
                    setError(e.error);
                    fetchChatHistory(newChat);
                } else {
                    setError(e.error);
                }
            } else if (e && e.name === "AbortError") {
                setError("Oh dear, just as I was delving into the details you needed, you went and stopped me in my tracks!");
            } else if (e && e.message === "Unauthorized") {
                setError("Unauthorized");
            } else {
                setError("Something went wrong! Please contact the administrator.");
            }
        } finally {
            setIsLoading(false);
            examplePromptClicked.current = false;
        }
    };

    const processLine = (line: string) => {
        if (line.trim()) {
            const parsedData = JSON.parse(line);
            if (parsedData.delta) {
                mergeDelta(loadingMessageRef.current, parsedData.delta, 'delta');
                setLoadingMessage({...loadingMessageRef.current})
            } else if (parsedData.set) {
                mergeDelta(loadingMessageRef.current, parsedData.set, 'set');
                setLoadingMessage({...loadingMessageRef.current})
            } else if (parsedData.error) {
                throw parsedData;
            } else {
                loadingMessageRef.current = parsedData;
            }
        }
    }

    const selectedChatType = () => {
        return chatTypes[chatType as string];
    }

    const repeatRequest = () => {
        return makeApiRequest(lastQuestionRef.current, selectedDocuments);
    }

    const scrollAfterRender = () => {
        setTimeout(() => {
            chatMessageStreamEnd.current?.scrollIntoView({behavior: "smooth"});
        }, 0);
    }

    const mergeDelta = (target: IConversationMessage, delta: any, mode = 'delta'): IConversationMessage => {
        const targetAsAny = target as any;
        for (const key of Object.keys(delta)) {
            if (typeof delta[key] === 'object' && delta[key] !== null && !Array.isArray(delta[key])) {
                if (!targetAsAny[key]) {
                    targetAsAny[key] = {};
                }
                mergeDelta(targetAsAny[key], delta[key], mode);
            } else {
                if (mode === 'delta') {
                    if (!targetAsAny[key]) {
                        targetAsAny[key] = '';
                    }
                    targetAsAny[key] += delta[key];
                } else if (mode === 'set') {
                    targetAsAny[key] = delta[key];
                }
            }
        }
        return targetAsAny;
    };

    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({behavior: "smooth"});
    }, [isLoading, loadingText]);

    const onExampleClicked = (example: string, selectedDocuments: IDocument[]) => {
        setChatId("");
        setAnswers([]);
        setSelectedChatHistory("");
        setWantSelectDocuments(selectedDocuments.length > 0);
        setSelectedDocuments(selectedDocuments);
        examplePromptClicked.current = true;
        !isLoading && makeApiRequest(example, selectedDocuments);
    };

    useEffect(() => {
        if (answers.length > 0 && !isLoading && error == undefined) {
            const answerToReinsert = answers.pop() as any as IConversationMessage;
            setAnswers([...answers, {
                ...answerToReinsert,
                elapsed_time: elapsedTime,
                expectedWordCount: expectedWordCount
            }]);
        }
    }, [elapsedTime]);

    useEffect(() => {
        if (chatId !== "") {
            window.history.replaceState({path: `${window.location.origin}/${chatId}`}, '', `${window.location.origin}/${chatId}`);
            if (loadingMessageRef.current.chat_id !== chatId) {
                onConversationClicked({chat_id: chatId, user: 'DUMMY'});
            }
        } else {
            window.history.replaceState({path: window.location.origin}, '', window.location.origin);
        }
    }, [chatId]);

    const onGetElapsedTime = (time: any) => {
        setElapsedTime(time);
    };

    const onResponseLimitationChange = (value: boolean) => {
        setResponseLimitationFrame(value);
        if (!value) {
            setExpectedWordCount(0);
        } else {
            setExpectedWordCount(100);
        }
    };

    const onConversationClicked = (result: any) => {
        if (!isLoading) {
            setSelectedChatHistory(result.chat_id);
            lastQuestionRef.current = result.user;
            setChatId(result.chat_id);
            setScroll(true);
            Object.keys(result).length === 3 ? setIsLoading(true) : setIsLoading(false);

            try {
                setIsLoadingConversation(true);
                getConversation(msal, result.chat_id).then(conversation_messages => {
                    let latestSelectedDocuments: IDocument[] = [];
                    let latestType: string = "chat";
                    conversation_messages.forEach((message: any) => {
                        if (!message.preference) {
                            latestSelectedDocuments = [];
                            latestType = "chat";
                            message.preference = {
                                expectedWordCount: message.expectedWordsCount
                            }
                        } else {
                            if (message.preference.selectedDocuments) {
                                latestSelectedDocuments = message.preference.selectedDocuments;
                            } else {
                                latestSelectedDocuments = [];
                            }
                            if (message.preference.type) {
                                latestType = message.preference.type;
                            } else {
                                latestType = "chat";
                            }
                            if (message.preference.expectedWordCount === undefined) {
                                message.preference.expectedWordCount = message.expectedWordsCount;
                            }
                        }
                    });
                    setIsLoadingConversation(false)
                    setAnswers(conversation_messages);
                    setScroll(false);
                    setIsLoading(false);
                    setWantSelectDocuments(latestSelectedDocuments.length > 0);
                    setSelectedDocuments(latestSelectedDocuments);
                    setChatType(latestType);
                }, error => {
                    console.error("Error fetching data:", error);
                    setIsLoadingConversation(false)
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const handleDocumentSelectionChange = (selectedDocuments: IDocument[]) => {
        setSelectedDocuments(selectedDocuments);
    };

    const handleWantSelectDocumentsChange = () => {
        setWantSelectDocuments(!wantSelectDocuments);
        if (!wantSelectDocuments) {
            setSelectedDocuments([]);
        }
    };

    const fetchChatHistory = (callBack: any) => {
        const timeout = () => {
            try {
                getConversations(msal).then(result => {
                    callBack && callBack();
                    setChatHistory(result);
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        timeout();
    };

    const deleteChatHistory = (chat_id: string) => {
        try {
            deleteConversation(msal, chat_id).then(() => {
                fetchChatHistory(newChat);
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const deleteAllChatHistory = () => {
        try {
            deleteAllConversations(msal).then(() => {
                fetchChatHistory(newChat);
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const newChat = () => {
        if (!isLoading && chatHistory.length <= 100) {
            examplePromptClicked.current = false;
            lastQuestionRef.current = "";
            error && setError(undefined);
            setAnswers([]);
            setWantSelectDocuments(false);
            setSelectedDocuments([]);
            setChatId("");
            setSelectedChatHistory("");
        }
    };

    const inputPlaceholder = () => {
        return selectedChatType()?.inputPlaceholders[wantSelectDocuments ? 'withDocuments' : 'simple'];
    };

    const hideAllOverlays = () => {
        toggleConversationContainer(true);
        toggleExamplesContainer(true);
    }

    const toggleConversationContainer = (hideOnly = false) => {
        const conversationContainer = document.getElementById("historyContainer");
        if (conversationContainer) {
            conversationContainer.style.display = !hideOnly && conversationContainer.style.display !== "unset" ? "unset" : "none";
        }
    }

    const toggleExamplesContainer = (hideOnly = false) => {
        const conversationContainer = document.getElementById("examplesContainer");
        if (conversationContainer) {
            conversationContainer.style.display = !hideOnly && conversationContainer.style.display !== "flex" ? "flex" : "none";
        }
    }

    useEffect(() => {
        fetchChatHistory(undefined);
    }, []);

    return (
        <div className={styles.container}>
            <div id="historyContainer" className={styles.historyContainer}>
                <ChatHistoryList
                    chatHistory={chatHistory}
                    selectedChatHistory={selectedChatHistory}
                    onHistoryClicked={onConversationClicked}
                    onNewChatClicked={newChat}
                    onDeleteChatHistory={deleteChatHistory}
                    onDeleteAllClicked={deleteAllChatHistory}
                    lastQuestionRef={lastQuestionRef.current}
                    isLoading={isLoading}
                    onToggleClicked={toggleConversationContainer}/>
            </div>
            <div className={styles.chatContainer}>
                <div className={`${styles.containerHeader} ${styles.chatContainerHeader}`}>
                    <div className={styles.chatContainerHeaderHiddenHistory}>
                        <PanelLeftExpandRegular onClick={() => toggleConversationContainer(false)}/>
                        <ComposeRegular
                            className={`${((lastQuestionRef && lastQuestionRef.current === '') || isLoading || chatHistory.length > 100) ? styles.svgDefaultDisable : styles.svgDefault}`}
                            onClick={newChat}/>
                    </div>
                    <div className={styles.chatContainerHeaderSettingsWrapper}>
                        <ChatSettings
                            chatTypes={chatTypes}
                            responseLimitationChecked={responseLimitationFrame}
                            onResponseLimitationChange={onResponseLimitationChange}
                            chatType={chatType}
                            onChatTypeChange={setChatType}
                            isSettingsPanelOpen={isSettingsOpen}
                            onSettingPanelClosed={closeSettingsPanel}
                        />
                    </div>
                    <div className={styles.flexCenter}>
                        <LauncherSettingsRegular
                            className={styles.chatContainerHeaderShowSettings}
                            onClick={openSettingsPanel}/>
                        <PanelRightExpandRegular
                            className={styles.chatContainerHeaderShowExamples}
                            onClick={() => toggleExamplesContainer(false)}/>
                    </div>
                </div>
                <div className={styles.chatContainerContent}
                     onClick={hideAllOverlays}>
                    {isLoadingConversation ? (
                        <div className={styles.chatContainerContentEmpty}>
                            <img alt={"idc logo"} src={idc_logo} className={styles.idcIcon}></img>
                            <Spinner className={styles.chatContainerContentSpinner}/>
                        </div>
                    ) : (<>
                        {!lastQuestionRef.current ? (
                            <div className={styles.chatContainerContentEmpty}>
                                <img alt={"idc logo"} src={idc_logo} className={styles.idcIcon}></img>
                                <h1>{selectedChatType().titlePrimary}</h1>
                                <h2>
                                    I'm skilled
                                    at {selectedChatType().secondaryTitle}
                                    <br/>
                                    <small>
                                        Go ahead and explore the options on the right side by giving them a shot
                                    </small>
                                </h2>
                            </div>
                        ) : (
                            <div className={`${styles.chatContainerContentMessages} ${styles.chatContainerContentMessagesNoDocuments}`}>
                                {answers.map((conversationMessage: IConversationMessage, index) => (
                                    <div key={'message_' + index}>
                                        <UserChatMessage message={conversationMessage.user}
                                                         selectedDocuments={conversationMessage.preference?.selectedDocuments}
                                                         userIcon={<UserAvatar
                                                             name={accountInf?.name}></UserAvatar>}/>
                                        <div className={styles.chatContainerContentMessageAnswerWrapper}>
                                            <Answer
                                                answer={conversationMessage.bot?.answer}
                                                references={conversationMessage.bot?.references}
                                                chatId={chatId}
                                                user={conversationMessage.user}
                                                gptperformancemetricsId={conversationMessage.gptperformancemetrics_id}
                                                history={onConversationClicked}/>
                                        </div>
                                        <AnswerTimerElapsed elapsedTime={conversationMessage.elapsed_time}
                                                            expectedWordsCount={conversationMessage.expectedWordCount}
                                                            messageType={conversationMessage.preference?.type}/>
                                    </div>
                                ))}
                                {isLoading && !scroll && (
                                    <>
                                        <UserChatMessage message={lastQuestionRef.current}
                                                         selectedDocuments={selectedDocuments}
                                                         userIcon={<UserAvatar
                                                             name={accountInf?.name}></UserAvatar>}/>
                                        <div className={styles.chatContainerContentMessageAnswerWrapper}>
                                            <AnswerLoading
                                                key={loadingMessage.id}
                                                loading={isLoading}
                                                message={loadingMessage}
                                                onScrollRequested={scrollAfterRender}
                                                getElapsedTime={onGetElapsedTime}/>
                                        </div>
                                    </>
                                )}
                                {error ? (
                                    <>
                                        <UserChatMessage message={lastQuestionRef.current}
                                                         selectedDocuments={[]}
                                                         userIcon={<UserAvatar
                                                             name={accountInf?.name}></UserAvatar>}/>
                                        <div>
                                            <AnswerError error={error.toString()} onRetry={repeatRequest}/>
                                        </div>
                                        <AnswerTimerElapsed elapsedTime={elapsedTime}/>
                                    </>
                                ) : null}
                                <div ref={chatMessageStreamEnd}/>
                            </div>
                        )}
                    </>)}

                    <div className={styles.chatContainerContentInput}>
                        {selectedChatType().supportSelectDocuments ?
                            (<div className={styles.chatContainerContentInputDocuments}>
                                <div className={styles.chatContainerContentInputDocumentsToggleWrapper}>
                                    <Toggle
                                        disabled={isLoading}
                                        className={styles.chatContainerContentInputDocumentsToggle}
                                        checked={wantSelectDocuments}
                                        onChange={() => handleWantSelectDocumentsChange()}/>
                                </div>
                                {wantSelectDocuments ? (
                                    <div className={styles.chatContainerContentInputDocumentsAutocomplete}>
                                        <DocumentAutoComplete preselectedDocuments={selectedDocuments}
                                                              onSelectionChange={handleDocumentSelectionChange}
                                                              disabled={isLoading}/>
                                    </div>
                                ) : (
                                    <div className={styles.chatContainerContentInputDocumentsToggleLabel}>
                                        <span>Do you want to select documents? (AI will focus only on the selected documents)</span>
                                    </div>
                                )}
                            </div>) : null}
                        <QuestionInput clearOnSend
                                       placeholder={inputPlaceholder()}
                                       disabled={isLoading}
                                       onSend={question => makeApiRequest(question, selectedDocuments)}/>
                        <div className={styles.chatContainerContentInputWarnMessage}>
                            All responses are generated by the IDC GenAI.
                            For accuracy, please review the results (text and numeric values) before using them in
                            client interactions or draft reports.
                        </div>
                    </div>
                    <ApplicationVersion/>
                </div>
            </div>
            <div id="examplesContainer" className={styles.examplesContainer}>
                <div className={styles.containerHeader}>
                    <div className={styles.flexCenter}>
                        <PanelRightContractRegular className={styles.chatContainerHeaderShowExamples}
                                                   onClick={() => toggleExamplesContainer(false)}/>
                    </div>
                </div>
                <div className={styles.examplesContainerContent}>
                    <ExampleList dataSource={[selectedChatType().key]}
                                 onExampleClicked={onExampleClicked}/>
                </div>
            </div>
        </div>
    );
};

export default Chat;

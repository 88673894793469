import styles from "./Answer.module.css";

interface Props {
    elapsedTime: number;
    expectedWordsCount?: number;
    messageType?: 'article' | 'chat';
}

export const AnswerTimerElapsed = ({elapsedTime, expectedWordsCount, messageType}: Props) => {
    const minutes = Math.floor((elapsedTime % 3600) / 60);
    const seconds = Math.floor(elapsedTime % 60);

    let expectedWordText = "";
    if (messageType !== 'article') {
        if (expectedWordsCount && expectedWordsCount > 0) {
            expectedWordText = "Response Length Limitation feature is turned on.";
        } else if (expectedWordsCount == 0) {
            expectedWordText = "Response Length Limitation feature is turned off.";
        }
    }
    return (
        <>
            <span className={styles.timerDisplay}>
                GPT4&nbsp;|&nbsp;Elapsed time: {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
                {expectedWordText && ` | ${expectedWordText}`}
            </span>
        </>
    );
};

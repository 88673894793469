import { Outlet, Link } from "react-router-dom";

import idc_logo from "../../assets/idc_logo.svg";

import styles from "./Layout.module.css";
import { AuthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";
import { Callout, DefaultButton } from "@fluentui/react";
import { useState, useEffect } from "react";
import { UserAvatar } from "../../components/UserAvatar";

const SCOPE = import.meta.env.VITE_SCOPE_URL;
const ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT;

const Layout = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [toggle, setToggle] = useState<boolean>(false);
    let activeAcc: any;
    if (instance) {
        activeAcc = instance.getActiveAccount();
    }

    let activeEnv: string = "";
    if (ENVIRONMENT) {
        activeEnv = " - " + ENVIRONMENT;
    }

    useEffect(() => {
        if (account) {
            localStorage.setItem("email", account.username);
            instance
                .acquireTokenSilent({
                    scopes: [SCOPE],
                    account: account
                })
                .then(() => {
                });
        }
    }, [account, instance]);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>
                            <img src={idc_logo}></img>GenAI{activeEnv}
                        </h3>
                    </Link>
                    <AuthenticatedTemplate>
                        <div onClick={() => setToggle(true)} className={styles.nameLabel} id="pop">
                            <UserAvatar name={activeAcc?.name}></UserAvatar>
                        </div>
                    </AuthenticatedTemplate>
                </div>
                {toggle && (
                    <Callout role="dialog" className={styles.callout} onDismiss={() => setToggle(false)} setInitialFocus beakWidth={10} target={"#pop"}>
                        <div className={styles.name}>{activeAcc?.name}</div>
                        <div className={styles.username}>{activeAcc?.username}</div>
                        <div className={styles.logoutBtn}>
                            <DefaultButton onClick={() => {
                                    instance.logoutRedirect();
                            }}>Logout</DefaultButton>
                        </div>
                    </Callout>
                )}
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;

import AsyncSelect from 'react-select/async';
import {useMsal} from "@azure/msal-react";
import debounce from "lodash.debounce";
import {lookupDocumentApi} from "../../api";
import styles from "./DocumentAutocomplete.module.css";
import {IDocument} from "../../api";
import {MultiValue} from "react-select";

interface IDocumentOption {
    value: string;
    label: string;
    title: string;
}

type OnSelectionChangeType = (onSelectionChange: IDocument[]) => void;

interface IDocumentAutoCompleteProps {
    preselectedDocuments?: IDocument[];
    onSelectionChange: OnSelectionChangeType;
    disabled: boolean;
}

const DocumentAutoComplete = ({preselectedDocuments, onSelectionChange, disabled}: IDocumentAutoCompleteProps) => {

    const msal = useMsal();
    let abortController = new AbortController();

    const fetchDocuments = (input: string, callback: any): void => {
        if (input.trim().length < 3 || (preselectedDocuments ? preselectedDocuments: []).length >= 5) {
            callback([]);
            return;
        }
        const selectedDocuments = preselectedDocuments ? preselectedDocuments.map(doc => doc.documentNumber) : [];
        abortController.abort(); // Cancel the previous request
        abortController = new AbortController(); // Create a new controller for the new request
        lookupDocumentApi(msal, input, selectedDocuments, abortController.signal).then((documents) => {
            callback(formatDocumentOptions(documents));
        }).catch(error => {
            if (error.name !== 'AbortError') {
                console.error('Fetch failed:', error);
            }
        });
    };

    const debounceLoadOptions = debounce(fetchDocuments, 300);

    const handleNoOptionsMessage = ({inputValue}: {
        inputValue: string
    }) => {
        if ((preselectedDocuments ? preselectedDocuments: []).length >= 5 && inputValue.length > 0) {
            return <span style={{ color: 'red' }}>You can select up to 5 documents only.</span>;
        } else if ((preselectedDocuments ? preselectedDocuments: []).length >= 5) {
            return 'You can select up to 5 documents only.';
        } else if (inputValue.length < 3) {
            return 'Please type at least 3 characters to begin searching for documents.';
        } else {
            return 'No options available';
        }
    };

    const handleChange = (selectedOptions: MultiValue<IDocumentOption>) => {
        const docs = selectedOptions.map(option => ({
            documentNumber: option.value,
            documentTitle: option.title
        }))
        onSelectionChange(docs);
    };

    const formatDocumentOptions = (documents?: IDocument[]): IDocumentOption[] | undefined => {
        return documents?.map(doc => ({
            value: doc.documentNumber,
            label: `${doc.documentNumber}: ${doc.documentTitle}`,
            title: doc.documentTitle
        }));
    };

    // @ts-ignore
    return (
        <div className={styles.root}>
            <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                value={formatDocumentOptions(preselectedDocuments)}
                onChange={handleChange}
                loadOptions={debounceLoadOptions}
                isDisabled={disabled}
                menuPlacement="top"
                noOptionsMessage={handleNoOptionsMessage}
                placeholder={"Search documents by title or number"}
            />
        </div>
    );
};

export default DocumentAutoComplete;